/*

 Input Masks

 */

function mask_init() {
    if (!$("body").hasClass("inputmask-inited")) {
        Inputmask.extendDefaults({
            showMaskOnHover: true,
            showMaskOnFocus: true,
            onKeyDown: function(event, buffer, caretPos, opts) {
                if (event && event.target && !$(event.target).inputmask('isComplete')) {
                    $(event.target).removeClass("mask-complete");
                    $(event.target).removeClass("mask-incomplete");
                }
            },
            oncomplete: function(event) {
                if (event && event.target) {
                    $(event.target).addClass("mask-complete");
                    $(event.target).removeClass("mask-incomplete");
                }
            },
            onincomplete: function(event) {
                if (event && event.target) {
                    $(event.target).removeClass("mask-complete");
                    $(event.target).addClass("mask-incomplete");
                }
            },
        });

        $("body").addClass("inputmask-inited")
    }

    $(".mask-phone-ru").filter(":not(.mask-inited)").inputmask({
        definitions: {
            '#': {
                validator: "[0-9]",
                cardinality: 1
            }
        },
        mask: "+7 (###) ### ## ##",
        placeholder: "_"
    }).addClass("mask-inited");

    $(".mask-time").filter(":not(.mask-inited)").inputmask({
        "alias":"hh:mm",
        placeholder: "_"
    }).addClass("mask-inited");

}



/*

 Form Validation

 */

function validate_init()
{
    if (!$.validator) return;

    if (!$("body").hasClass("validate-inited")) {

        $(document).on("reset", ".form-validate", function () {
            $(this).find(".form__item, :input").removeClass("error");
            setTimeout(function () {
                $(":input").trigger("change");
                $(".checkbox-plain-js input[type='checkbox'], .checkbox-plain-js input[type='radio']").trigger("change-pseudo");
            }, 50);
        })

        $.validator.setDefaults({
        });

        $.extend($.validator.messages, {
            required: "Обязательное поле.",
            email: "Некорректный E-mail.",
            phonecomplete: "Неполный номер телефона.",
        });

        $.validator.addMethod("email", function( value, element ) {
            return this.optional( element ) || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value ); // /^[a-zA-Z\-\._]+@[a-z0-9\-]+(\.[a-z0-9\-]+)*\.[a-z]+$/i
        });

        $.validator.addMethod("phonecomplete", function(value, element) {
            return this.optional(element) || (value.replace(/\D/, "").length >= 7 && $(element).inputmask('isComplete'));
        });

        $.validator.addMethod("maskcomplete", function(value, element) {
            return this.optional(element) || (value.replace(/\D/, "").length >= 7 && $(element).inputmask('isComplete'));
        });

        $.each($.validator.methods, function (key, value) {
            $.validator.methods[key] = function () {
                if(arguments.length > 0) {
                    arguments[0] = $.trim(arguments[0]);
                }
                return value.apply(this, arguments);
            };
        });

        $(document).on("keyup blur change check-form", ".form-disabled-until-valid :input", function () {
            var $form = $(this).closest(".form-disabled-until-valid");
            var $button = $form.find(".disabled-until-valid");
            $button.prop('disabled', !$form.validate().checkForm());
        });

        $(document).on("change change-checkbox-valid", "input[type='checkbox'], input[type='radio']", function () {
            var $form = $(this).closest("form");
            var validator = $form.data("validator");
            if (validator) $(this).valid();
        });

        $("body").addClass("validate-inited");
    }

    var $forms = $(".form-validate").not(".form-validate-inited");
    $forms.each(function(){
        $(this).validate({
            onkeyup: false,
            normalizer: function( value ) {
                return $.trim( value );
            },
            errorClass: 'form__error',
            validClass: 'valid',
            errorElement: 'div',
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('error').removeClass('valid');
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).addClass('valid').removeClass('error');
            },
            errorPlacement: function(error, element) {
                $(element).closest(".form__item-field").append(error);
            },
            submitHandler: function(form, e) {
                $(form).trigger("submit.valid");
            }
        });

        $(this).addClass("form-validate-inited")
    });

    $(".form-disabled-until-valid").find(":input").first().trigger("check-form");

}