$(function () {
    document_ready();
    window_onload();
});

function document_ready() {
    ts = (new Date()).getTime();
    $("body").addClass("ready");

    responsive_init();
    init_event_handlers();

    bind_widgets();
    $(document).ajaxStop(function () {
        bind_widgets();
    });

    scroll_animations_init();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

function window_onload() {
    ts = (new Date()).getTime();
    $("body").addClass("win-loaded").removeClass("win-not-loaded");

    $(document).imagesLoaded(function () {
        te = (new Date()).getTime();
        console.log("Images Load Time: ", te - ts);
        setTimeout(function () {
            $("body").addClass("loaded").removeClass("not-loaded");
            expand_it_init();
        }, 10);
    });

    responsive_update();

    te = (new Date()).getTime();
    console.log("Window Load Time: ", te - ts);
}

$(window).scroll(function () {
    if (typeof $("body").data("scrollval") === "undefined") $("body").data("scrollval", 0);

    scroll_animations();

    $("body").data("scrollval", $(window).scrollTop());
});

$(window).on("resize orientationchange", function (e) {
    responsive_update();
    scroll_animations();
});

function init_event_handlers() {

    lazy_load_init();
    fix_touch_hovers();
    click_touch_init();
    textfield_init();
    tabs_init();
    goto_init();
    loader_init();
    toggle_element_init();


    // HEADER and MENU

    $(".header__top").clone().addClass('header__top--fixed').insertAfter($(".header__top"))

    $(document).on("click", ".js-menu-switcher", function (e) {
        $("body").toggleClass("menu-overlay-active");
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        $("body").removeClass("menu-overlay-active");
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".menu-overlay, .js-menu-switcher").length) {
            $("body").removeClass("menu-overlay-active");
        }
    });


    // SLIDER pagination handlers

    $(window).on("scroll load slider-pagination-fixed-update", function(e){
        var $w = $(window);
        $(".js-slider-pagination-fixed").each(function(){
            var $s = $(this).closest(".swiper-slider");
            $(this).toggleClass("fixed", $w.scrollTop() + $w.height() >= $s.offset().top + $(this).outerHeight() && $w.scrollTop() + $w.height() <= $s.offset().top + $s.outerHeight());
            $(this).toggleClass("active", $w.scrollTop() + $w.height() > $s.offset().top + Math.min($w.height()/2, 300));
        });
    });

    $(window).on("scroll resize load slider-menu-fixed-update", function(e){
        menu_scrolled();
    });


    // SECTION background scroll handlers

    $(window).on("scroll load", function(e){
        var $w = $(window);
        $(".js-bg-scroll-horz").each(function(){
            var value = (($w.scrollTop() + $w.height()/3*2) - $(this).offset().top) / Math.max($(this).outerHeight() + $w.height());
            if (value > 1) value = 1;
            if (value < 0) value = 0;
            $(this).css("background-position-x", value*100 + "%");
        });
    });


    // FORM handler

    $(document).on("submit.valid click perform-action", ".js-action", function(e){
        var $this = $(this);
        if ($this.is("form") && e.type != "submit") {
            return true;
        }
        e.preventDefault();
        var url = $this.attr("href");
        var method = "get";
        var data = null;
        if ($this.is("form")) {
            data = $this.serialize();

            if (window.FormData !== undefined) {
                data = new FormData($this[0]);
            }

            url = $this.attr("action");
            method = $this.attr("method");
        }
        if ($this.attr("data-action-url")) {
            url = $this.attr("data-action-url");
        }
        if ($this.attr("data-action-method")) {
            method = $this.attr("data-action-method");
        }

        $.ajax({
            url: url,
            type: method,
            data: data,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: function () {
                $this.find(":input").prop("disabled", true).closest(".textfield-wrapper").addClass("disabled");
                var $btn = $this.find(".loader-on-submit");
                loader_add($btn);
            },
            complete: function (response) {
                $this.find(":input").not("[data-disabled]").prop("disabled", false).closest(".textfield-wrapper").removeClass("disabled");
                $this.find(":password").val("");
                var $btn = $this.find(".loader-on-submit");
                loader_remove($btn);
                $this.trigger("after-action-complete");
            },
            success: function (response) {
                if (response.popup) {
                    if (response.text) {
                        $.fancybox.close();
                        $.fancybox.open(response.text, $.fancybox.options_modal);
                    }
                }
                else {
                }
                $this.trigger("after-action-success", response);
            },
            error: function (jqXHR) {
                form_process_errors($this, jqXHR);
                $this.trigger("after-action-error");
            }
        });
    });

    $(document).on("after-action-success", ".js-action-reset-after", function(e){
        $(this)[0].reset();
    });

}

function form_process_errors($form, jqXHR) {
    console.log(jqXHR.status, jqXHR.responseJSON);
    var error_text;
    if (jqXHR.status === 401) {
        error_text = 'Access denied.';
    }
    else if (jqXHR.status === 422) {
        var errors = jqXHR.responseJSON;
        var index = 0;
        $.each(errors, function(field_name, field_errors){
            $field = $form.find("[name='" + field_name + "']");
            if (!index) {
                $field.trigger("focus");
            }
            $field.addClass("error");
            $.each(field_errors, function(error_index, error_text) {
                $field.parent().append("<div class='form__error form__error--"+error_index+"'>" + error_text + "</div>");
            });
            index++;
        });
    }
    else {
        error_text = 'Try again.';
    }
    alert(error_text);
}

function bind_widgets() {
    tel_replace_init();
    img_to_bg();
    slider_init();
    placeholder_init();
    time_picker();
    date_picker();
    selectbox_init();
    fancybox_init();
    validate_init();
    mask_init();

    // SLIDER pagination (slider handlers)
    var $sliders = $(".js-slider-pagination-fixed").closest(".swiper-slider").find('.swiper-container');
    $sliders.each(function(){
        var $this = $(this);
        var $s = $(this).closest('.section');
        var swiper = $(this)[0].swiper;
        if (swiper) {
            swiper.on('slideChangeTransitionEnd', function () {
                $(window).trigger("slider-pagination-fixed-update");
                var y = 100;
                //if ($this.offset().top + $this.outerHeight() < $(window).scrollTop() + y) {
                goto_object($s);
                //}
                menu_scrolled();
            });
        }
    });
}