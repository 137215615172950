
/*

 Textfields (with reset icon, adaptive placeholders etc)

 */

function textfield_init($o)
{
    if (typeof $o === "undefined") $o = $(".textfield");

    if (!$("body").hasClass("textfield-inited-globally")) {

        $(document).on("keydown keyup focus blur textfield-filled", ".textfield", function (e) {
            var is_filled = !!$(this).val();
            var $placeholder = $(this).nextAll(".textfield-placeholder").first();
            if (e.type === "textfield-filled") { // remove inline attribute value for CSS [value] selector purpose
                if ($(this).val().length) $(this).prop("value", $(this).val());
                $(this).removeAttr("value");
            }
            if ($placeholder.length && $placeholder.attr("data-label-short"))
            {
                if (e.type == "focusin" || e.type == "focusout")
                {
                    var label;
                    if (e.type == "focusin")
                    {
                        label = $placeholder.attr("data-label-short");
                        if (!$placeholder.attr("data-label-long"))
                        {
                            $placeholder.attr("data-label-long", $placeholder.html());
                        }
                    }
                    if (e.type == "focusout" && !is_filled)
                    {
                        if ($placeholder.attr("data-label-long"))
                        {
                            label = $placeholder.attr("data-label-long");
                        }
                    }
                    $placeholder.html(label);
                }
            }
            $(this).toggleClass("filled", is_filled);
        });

        $(".textfield").trigger("textfield-filled");
        $(document).ajaxStop(function () {
            $(".textfield").trigger("textfield-filled");
        });

        $("body").addClass("textfield-inited-globally")
    }

    $o.trigger("textfield-filled");

}

function placeholder_init() {
    $(":input[placeholder]").not(".placeholder-inited").placeholder().addClass("placeholder-inited");
}


/*

Time Picker

 */

function time_picker() {
    $(".time-picker").not(".time-picker-inited").each(function () {
        $(this).timepicker({
            appendTo: $(this).parent(),
            timeFormat: 'H:i',
            step: 30,
            minTime: $(this).data("min"),
            maxTime: $(this).data("max"),
            showOn: ['focus', 'click']
        });
        $(this).addClass("time-picker-inited");
    });
}



/*

Date Picker

 */

function date_picker()
{
    $(".date-picker").not(".date-picker-events-binded").addClass("date-picker-events-binded").each(function(){
        $datepick = $(this);
        $datepick.datepick({
            renderer: $.extend(
                {}, $.datepick.defaultRenderer,
                { picker: '<div class="datepick"><div class="datepick-nav"><div class="datepick-nav-year">{link:prevJump}<span class="datepick-nav-year-name"></span>{link:nextJump}</div><div class="datepick-nav-month">{link:prev}<span class="datepick-nav-month-name"></span>{link:next}</div></div>{months}{popup:start}<div class="datepick-ctrl">{link:clear}{link:close}</div>{popup:end}<div class="datepick-clear-fix"></div></div>'}
            ),
            dateFormat: ($(this).data("datepicker-format")) ? $(this).data("datepicker-format") : 'd M yyyy',
            monthNamesShort: ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"],
            dayNamesShort: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
            firstDay: 1,
            minDate: $(this).data("min-date") || "-50y",
            maxDate: $(this).data("max-date") || "T",
            prevText: '<',
            todayText: 'MM',
            nextText: '>',
            useMouseWheel: false,
            commandsAsDateFormat: true,
            showOtherMonths: false,
            selectDefaultDate: true,
            showAnim: '',
            pickerClass: ($(this).data("datepicker-class")) ? $(this).data("datepicker-class") : '',
            popupContainer: ($(this).data("datepicker-container")) ? $(this).closest($(this).data("datepicker-container")) : $(this).parent(),
            rangeSelect: $(this).data("range-select"),
            monthsToShow: ($(this).data("months-to-show")) ? $(this).data("months-to-show") : 1,
            onSelect: function(date) {
                $this = $(this);
                datepick_range_set($this, date[0]);
                if ($this.is("input")) $this.trigger("change").trigger("blur");
            },
            onShow: function(picker, inst) {
                setTimeout(function() {
                    var $picker_popup = picker.parent();
                    if ($picker_popup.length) {
                        $picker_popup.addClass("active invisible");
                        datepick_reposition($picker_popup);
                        $picker_popup.removeClass("invisible");
                    }
                }, 10);
                picker.find('.datepick-cmd-prev,.datepick-cmd-prevJump').html('<svg class="icon"><use xlink:href="#icon-chevron-left"></use></svg>');
                picker.find('.datepick-cmd-next,.datepick-cmd-nextJump').html('<svg class="icon"><use xlink:href="#icon-chevron-right"></use></svg>');
                picker.find('.datepick-nav-month-name').text($.datepick.formatDate('MM', $.datepick.newDate(inst.drawDate.getFullYear(), inst.drawDate.getMonth()+1, 1)));
                picker.find('.datepick-nav-year-name').text($.datepick.formatDate('yyyy', $.datepick.newDate(inst.drawDate.getFullYear(), inst.drawDate.getMonth()+1, 1)));
                picker.find('.datepick-month a').removeAttr("title");
                if ($.fn.mousewheel)
                {
                    inst.div.unmousewheel();
                }
            },
            onChangeMonthYear: function(year, month) {
                _this = $(this);
                setTimeout(function(){
                    year_format = '';
                    if (new Date().getFullYear() !== year) year_format = " yyyy";
                    $('.datepick-popup .datepick-cmd-today').text($.datepick.formatDate('MM' + year_format, $.datepick.newDate(year, month, 1)));
                }, 0);
            }
        });
        $datepick.on("datepick-range-set", function(){
            var date = $this.datepick('getDate');
            datepick_range_set($(this), date[0]);
            if ($(this).is("input")) $(this).trigger("change");
        });
    });

    if (!$("body").hasClass("datepick-popup-onresize-inited")) {
        $(window).on("resize", function() {
            $(".datepick-popup").each(function() {
                datepick_reposition($(this));
            });
        });
        $("body").addClass("datepick-popup-onresize-inited");
    }
}

function datepick_reposition($picker_popup) {
    var parent_height = $(".wrap").height();
    var parent_offset = 0;
    var $popup_scroll = $picker_popup.closest(".fancybox-slide");
    if (!$popup_scroll.length) {
        $popup_scroll = $(".wrap");
    }
    if ($popup_scroll.length) {
        parent_offset = $popup_scroll.offset().top;
        parent_height = $popup_scroll.height();
    }
    if ($picker_popup.offset().top - parent_offset + $picker_popup.outerHeight() > parent_height) {
        $picker_popup.addClass("from-bottom");
    }
    if ($picker_popup.offset().top - parent_offset < 0) {
        $picker_popup.removeClass("from-bottom");
    }
}


function datepick_range_set($this, date) {
    // Проверяем диапазоны в группах дат, и если необходимо учитываем диапазоны внутри групп (на одной линии)
    if ($this.attr("data-range-group")) {
        var $group = $($this.attr("data-range-group"));
        if ($group.length) {
            var min = 1;
            var $range_line = $();
            $group.each(function(){
                var $this_range_line = $(this).closest($(this).attr("data-range-line"));
                var default_min = $.datepick.determineDate($(this).attr("data-min-date"));
                var current_min = Math.max(min + (($this_range_line[0] != $range_line[0])?24*60*60*1000:0), default_min?default_min:1);
                $(this).attr("data-min-in-group", current_min);
                if (!$(this).is("[disabled]")) {
                    $(this).datepick('option', 'minDate', new Date(current_min) || null);
                }
                if ($(this).datepick('getDate')[0]) {
                    min = Math.max(min, $(this).datepick('getDate')[0]);
                    $range_line = $this_range_line;
                }
            });
            var max = Infinity;
            var $range_line = $();
            $($group.get().reverse()).each(function(){
                var $this_range_line = $(this).closest($(this).attr("data-range-line"));
                var default_max = $.datepick.determineDate($(this).attr("data-max-date"));
                var current_max = Math.min(max - (($this_range_line[0] != $range_line[0])?24*60*60*1000:0), default_max?default_max:Infinity);
                $(this).attr("data-max-in-group", current_max);
                if (!$(this).is("[disabled]")) {
                    $(this).datepick('option', 'maxDate', new Date(current_max) || null);
                }
                if ($(this).datepick('getDate')[0]) {
                    max = Math.min(max, $(this).datepick('getDate')[0]);
                    $range_line = $this_range_line;
                }
            });
        }
    }
    var group = $this.data("datepicker-range-group");
    if (typeof group !== "undefined") {
        if ($this.data("datepicker-range-group-index") == 0) {
            $("input[data-datepicker-range-group='" + group + "'][data-datepicker-range-group-index='1']").datepick('option', 'minDate', date || null);
        } else {
            $("input[data-datepicker-range-group='" + group + "'][data-datepicker-range-group-index='0']").datepick('option', 'maxDate', date || null);
        }
    }
    var $maxfor = $($this.data("datepicker-range-max-for"));
    if ($maxfor.length) {
        $maxfor.datepick('option', 'maxDate', date || null);
    }
    var $minfor = $($this.data("datepicker-range-min-for"));
    if ($minfor.length) {
        console.log($minfor, date);
        $minfor.datepick('option', 'minDate', date || null);
    }
}



/*

Selectboxes

 */

function selectbox_init($o) {

    if (!$().select2) return;

    if (!$("body").hasClass("selectbox-inited")) {

        $(document).on("focus", '.select2-search__field', function(e) { // resolve focus problem in fancybox popup
            e.stopPropagation();
        });



        $.fn.select2.amd.define('select2/data/maximumSelectionLength1',[

        ], function (){
            function MaximumSelectionLength (decorated, $e, options) {
                this.maximumSelectionLength = options.get('maximumSelectionLength1');

                decorated.call(this, $e, options);
            }

            MaximumSelectionLength.prototype.query =
                function (decorated, params, callback) {
                    var self = this;

                    this.current(function (currentData) {
                        var count = currentData != null ? currentData.length : 0;
                        if (self.maximumSelectionLength1 > 0 &&
                            count >= self.maximumSelectionLength1) {
                            self.trigger('results:message', {
                                message: 'maximumSelected',
                                args: {
                                    maximum: self.maximumSelectionLength1
                                }
                            });
                            return;
                        }
                        decorated.call(self, params, callback);
                    });
                };

            return MaximumSelectionLength;
        });

        $("body").addClass("selectbox-inited");
    }

    $.fn.select2.amd.require([
        "select2/utils",
        "select2/dropdown",
        "select2/dropdown/attachContainer",
        "select2/dropdown/search",
        "select2/selection/search",
        "select2/dropdown/search",
        "select2/selection/base",
        "select2/selection/single",
        "select2/selection/multiple",
        "select2/core",
        "select2/selection/allowClear"
    ], function (Utils, DropdownAdapter, AttachContainer, DropdownSearch, SelectionSearch, DropdownSearch, BaseSelection, SingleSelection, MultipleSelection, Select2Core, AllowClear) {

        SingleSelection.prototype.render = function () {
            var $selection = SingleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--single');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<span class="select2-selection__rendered"></span>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<svg class="icon icon--chevron-down"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron-down"></use></svg>' +
                '</span>'
            );

            return $selection;
        };

        MultipleSelection.prototype.render = function () {
            var $selection = MultipleSelection.__super__.render.call(this);

            $selection.addClass('select2-selection--multiple');

            $selection.html(
                '<span class="select2-placeholder"><span class="select2-placeholder__inner">' + this.options.get('placeholder') +'</span></span>' +
                '<ul class="select2-selection__rendered"></ul>' +
                '<span class="select2-selection__arrow" role="presentation">' +
                '<svg class="icon icon--chevron-down"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron-down"></use></svg>' +
                '</span>'
            );
            $selection.prop("tabindex", this.$element.data('old-tabindex'));

            return $selection;
        };

        DropdownSearch.prototype.render = function (decorated) {
            var $rendered = decorated.call(this);

            var $search = $(
                '<span class="select2-search select2-search--dropdown">' +
                '<div class="textfield-wrapper">' +
                '<input class="select2-search__field textfield" type="search" tabindex="-1"' +
                ' autocomplete="off" autocorrect="off" autocapitalize="off"' +
                ' spellcheck="false" role="textbox" placeholder=" " />' +
                '<label class="textfield-placeholder" for="">Поиск по названию</label>' +
                '<span class="textfield-reset"></span>' +
                '</div>' +
                '</span>'
            );

            this.$searchContainer = $search;
            this.$search = $search.find('input');

            $rendered.prepend($search);

            return $rendered;
        };

        SelectionSearch.prototype._transferTabIndex = function (decorated) {
            // remove tabindex transfer
        };

        SelectionSearch.prototype.searchRemoveChoice = function (decorated, item) {

            this.trigger('unselect', {
                data: item
            });

            this.$search.val('');
            this.handleSearch();
        };

        AllowClear.prototype.update = function (decorated, data) {
            decorated.call(this, data);

            this.$selection.removeClass("select2-selection--with-clear");
            if (this.$selection.find('.select2-selection__placeholder').length > 0 ||
                data.length === 0) {
                return;
            }

            var $remove = $(
                '<span class="select2-selection__clear">' +
                '&times;' +
                '</span>'
            );
            $remove.data('data', data);

            this.$selection.addClass("select2-selection--with-clear").find('.select2-selection__rendered').prepend($remove);
        };

        if (typeof $o === "undefined") $o = $('select.selectbox');
        $o.not('.selectbox-inited').each(function () {
            var options = {
                tags: false,
                theme: "default",
                dropdownAutoWidth: true,
                dropdownParent: $('.wrap')
            };
            if ($(this).data("selectbox-show-values"))
            {
                options["templateSelection"] = function(state) {
                    if (!state.id) {
                        return state.text;
                    }
                    var $state = state.element.value;
                    return $state;
                };
            }
            if (!$(this).data("selectbox-search"))
            {
                options["minimumResultsForSearch"] = Infinity;
            }
            if ($(this).data("selectbox-autowidth"))
            {
                options["width"] = "auto";
            }
            if ($(this).data("selectbox-tags-source"))
            {
                options["dropdownParent"] = $(this).closest(".selectbox-tags__destination").find("select").parent();
                options["theme"] += " select2-container--tags-destination";
            }
            else
            {
                if ($(this).data("selectbox-tags"))
                {
                    var $towrap = $(this);
                    if ($(this).closest(".textfield-wrapper").length)
                    {
                        $towrap = $(this).closest(".textfield-wrapper");
                    }
                    var $cloned_select_html = $towrap.clone();
                    $towrap.wrap("<div class='selectbox-tags'>");
                    $cloned_select_html.insertAfter($towrap);
                    $towrap.wrap("<div class='selectbox-tags__source'>");
                    $cloned_select_html.wrap("<div class='selectbox-tags__destination'>");
                    options["dropdownParent"] = $towrap;
                    var $cloned_select_select = $cloned_select_html;
                    if ($cloned_select_html.find("select").length)
                    {
                        $cloned_select_select = $cloned_select_html.find("select");
                    }
                    var $cloned_select_placeholder = $cloned_select_html.find(".textfield-placeholder");
                    if ($cloned_select_placeholder.length)
                    {
                        $cloned_select_placeholder.html($cloned_select_placeholder.data("tags-label")?$cloned_select_placeholder.data("tags-label"):"");
                    }
                    $cloned_select_select.attr("data-placeholder", "").removeAttr("data-selectbox-aggregate-min");
                    $cloned_select_select.find("option[value!='']").remove();
                    $(this).removeAttr("name");
                    $(this).data("selectbox-tags-destination", $cloned_select_select);
                    $cloned_select_select.data("selectbox-tags-source", $(this));
                    if ($cloned_select_select.data("selectbox-tags-source"))
                    {
                        selectbox_init($cloned_select_select);
                    }
                    $(this).on("change", function(){
                        var $dest = $(this).data("selectbox-tags-destination");
                        sync_selects_append($(this)[0], $dest[0]);
                    });
                    $cloned_select_select.on("change", function(){
                        var $source = $(this).data("selectbox-tags-source");
                        sync_selects($(this)[0], $source[0]);
                    });
                    options["theme"] += " select2-container--tags-source";
                    options["containerCssClass"] = "select2-selection--notags";
                    options["dropdownCssClass"] = "select2-dropdown--notags";
                }
            }
            if (!$(this).data("selectbox-tags"))
            {
                options["containerCssClass"] = "select2-selection--notags";
                options["dropdownCssClass"] = "select2-dropdown--notags";
            }
            if ($(this).data("selectbox-tags-create"))
            {
                options["tags"] = $(this).data("selectbox-tags-create");
            }
            if ($(this).data("selectbox-allow-clear"))
            {
                options["allowClear"] = $(this).data("selectbox-allow-clear");
            }
            if ($(this).data("selectbox-limit"))
            {
                options["maximumSelectionLength"] = $(this).data("selectbox-limit");
            }
            if ($(this).prop("multiple"))
            {
                options["closeOnSelect"] = false;
            }
            if ($(this).data("selectbox-dropdown-parent"))
            {
                options["dropdownParent"] = $($(this).data("selectbox-dropdown-parent"));
            }
            if ($(this).data("selectbox-ajax"))
            {
                options["ajax"] = $(this).data("selectbox-ajax");
            }

            if ($(this).closest(".fancybox-slide").length)
            {
                options["theme"] += " select2-container--in-fancybox";
            }
            //$(this).find("option[value='']:empty").remove();
            $(this).off("change.selectbox").on("change.selectbox", function () {
                if (!$(this).prop("multiple")) {
                    $(this).select2('close');
                }
                $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val());
            }).select2(options).addClass("selectbox-inited");
            $(this).data('select2').$container.toggleClass("select2-container--filled", !!$(this).val());

            $(this).data('select2').on("results:all", function (params) {
                var $options = this.$dropdown.find(".select2-results__options");
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                }, 10);
            });

            $(this).off("select2:open").on("select2:open", function (e) {
                var _this = this;
                var $options = $(_this).data('select2').$dropdown.find(".select2-results__options");
                $(_this).data('select2').$dropdown.addClass("select2-container--dropdown");
                if ($(this).data("selectbox-dropdown-nowrap"))
                {
                    $(_this).data('select2').$dropdown.addClass("select2-container--dropdown-nowrap");
                }
                setTimeout(function(){
                    $options.toggleClass("select2-results__options--scrollbar", $options.hasScrollBar());
                    if (!$(_this).data('select2').$dropdown.closest(".select2-container--tags-source").length)
                    {
                        if ($(".touchevents").length) forceRedraw($(_this).data('select2').$dropdown[0]);
                        else $(_this).data('select2').$dropdown.css("transform", "translateZ(0)");
                    }
                }, 1);
            });

            $(this).off("select2:unselecting").on("select2:unselecting", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                opts.set('disabled', true);
                setTimeout(function() {
                    opts.set('disabled', false);
                }, 1);
            });

            $(this).off("select2:select").on("select2:select", function (e) {
                var _this = this;
                setTimeout(function() { // Hotfix for correct dropdown position if more than maximumSelectionLength
                    $(window).scrollTop($(window).scrollTop()+1);
                    $(window).scrollTop($(window).scrollTop()-1);
                }, 1);
            });

            $(this).off("select2:select select2:unselect select2:selection-aggregate").on("select2:select select2:unselect select2:selection-aggregate", function (e) {
                var select2 = $(this).data('select2');
                var opts = select2.options;
                var $rendered = select2.$selection.find(".select2-selection__rendered");
                if ($rendered.filter("span").length) {
                    var current_text = $.trim($rendered.filter("span").text());
                    if (current_text) {
                        $rendered.text(current_text);
                    }
                }

                /* http://stackoverflow.com/a/39787191 */
                var values = [];
                var output = "";
                var agg_min = $(this).data("selectbox-aggregate-min");
                $(this).find("option:selected").each(function(i, selected){
                    values[i] = $(selected).text();
                });
                if (values.length >= agg_min)
                {
                    output = "<li class='select2-selection__choice'>Выбрано "+values.length+"</li>";
                }
                if (output)
                {
                    $rendered.children().not(".select2-selection__clear").remove();
                    $rendered.prepend(output);
                }
            }).trigger("select2:selection-aggregate");

        });
    }, null, true);

}

function sync_selects(el1, el2) {
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else
    {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        for (var i = 0, len = options1.length; i < len; i++)
        {
            var val = options1[i].value;
            $(options2).filter("[value='"+val+"']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

function sync_selects_append(el1, el2) {
    var change_reverse = $(el1).data("change-reverse");
    if ($(el1).data("change-triggering"))
    {
        return false;
    }
    if (!el1)
    {
        return false;
    }
    else {
        var options1 = el1.getElementsByTagName('option');
        var options2 = el2.getElementsByTagName('option');
        if (change_reverse) {
            options3 = options1;
            options1 = options2;
            options2 = options3;
            el3 = el1;
            el1 = el2;
            el2 = el3;
        }
        for (var i = 0, len = options1.length; i < len; i++) {
            var val = options1[i].value;
            if (!change_reverse) {
                var exist = $(options2).filter("[value='" + val + "']").length;
                if (!exist) {
                    $(el2).append($(options1[i]).clone());
                }
            }
            $(options2).filter("[value='" + val + "']").prop("selected", options1[i].selected);
        }
        $(el2).data("change-triggering", true).trigger("change").trigger("select2:selection-aggregate").data("change-triggering", false);
    }
}

(function ($) {
    $.fn.refreshDataSelect2 = function (data) {
        this.select2('data', data);

        // Update options
        var $select = $(this[0]);
        var options = data.map(function(item) {
            return '<option value="' + item.id + '">' + item.text + '</option>';
        });
        $select.html('<option value=""></option>');
        $select.append(options.join('')).trigger("change");
    };
})(jQuery);